import { fetchRules } from '/@/app/services/rules';
import { useStateMapper } from '/@/app/composables/useStateMapper';

const { canContinue, toState } = useStateMapper();

const rules = {};
export function useState() {
  const getPageState = blocks => {
    const state = {};
    blocks.forEach(block => {
      state[block.id] = toState(block);
    });
    return state;
  };

  const getDisabled = (blocks, state) => {
    for (let block of blocks) {
      if (!canContinue(block, state[block.id])) {
        return true;
      }
    }
    return false;
  };

  const getRules = async page => {
    return new Promise(resolve => {
      if (!rules[page]) {
        const { onSuccess } = fetchRules(page);
        onSuccess(({ data }) => {
          rules[page] = data;
          resolve(data);
        });
      } else {
        resolve(rules[page]);
      }
    });
  };

  return {
    getPageState,
    getDisabled,
    getRules,
  };
}
